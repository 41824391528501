<template>
  <h1 v-if="!pokemon">Espere...</h1>
  <div v-else>
    <h1>¿Quién es este pokemon?</h1>
    <PokemonPicture :pokemonId="pokemon.id" :showPokemon="showPokemon" />
    <PokemonOptions @selection="checkAnswer" :pokemons="pokemonArr" />

    <template v-if="showAnswer">
      <h2>{{ message }}</h2>
      <button @click="newGame">Nuevo juego</button>
    </template>
  </div>
</template>


<script>
import PokemonPicture from "@/components/PokemonPicture.vue";
import PokemonOptions from "@/components/PokemonOptions.vue";
import getPokemonsOptions from "@/helpers/getPokemonOptions";

export default {
  name: "PokemonPage",
  components: {
    PokemonPicture,
    PokemonOptions,
  },
  data() {
    return {
      pokemonArr: [],
      pokemon: null,
      showPokemon: false,
      showAnswer: false,
      message: "",
    };
  },
  methods: {
    async mixPokemonArray() {
      this.pokemonArr = await getPokemonsOptions();
      const randonInt = Math.floor(Math.random() * 4);
      this.pokemon = this.pokemonArr[randonInt];
    },
    checkAnswer(selected) {
      if (this.showAnswer) return;
      this.showAnswer = true;
      if (selected === this.pokemon.id) {
        this.showPokemon = true;
        return (this.message = `Correcto! ${this.pokemon.name} es su nombre`);
      }
      this.message = "Vaya! Nop";
    },
    newGame() {
      this.pokemon = null;
      this.showAnswer = false;
      this.showPokemon = false;
      this.mixPokemonArray();
    },
  },
  mounted() {
    this.mixPokemonArray();
  },
};
</script>

<style>
</style>